import { Message } from "../Validation/Message";

export const Messages = {
    FIRM_SETTINGS: new Message("FIRM_SETTINGS", "Firm Settings"),
    EXPERT_INTEGRATION: new Message("EXPERT_INTEGRATION", "Expert"),
    INTEGRATIONS: new Message("INTEGRATIONS", "Integrations"),
    FIRM_OPTIONS: new Message("FIRM_OPTIONS", "Firm Options"),
    SYNONYM_MANAGEMENT: new Message("SYNONYM_MANAGEMENT", "Synonym Management"),
    SANCTIONS_IMPORT: new Message("SANCTIONS_IMPORT", "Sanctions Lists"),
    DATA_IMPORT: new Message("DATA_IMPORT", "Data Imports"),
    INFORMATION_BARRIERS: new Message("INFORMATION_BARRIERS", "Information Barriers"),
    API_KEY_MANAGEMENT: new Message("API_KEY_MANAGEMENT", "API Keys"),
    GENERAL: new Message("GENERAL", "General"),
    SEARCH_REQUESTS: new Message("SEARCH_REQUESTS", "Search Requests"),
    APPLY_FUZZY_SEARCH_BY_DEFAULT_FOR_SEARCH_REQUESTS: new Message("APPLY_FUZZY_SEARCH_BY_DEFAULT_FOR_SEARCH_REQUESTS", "Apply fuzzy search by default for search requests"),
    ALLOW_APPROVERS_TO_BE_DESIGNATED: new Message("ALLOW_APPROVERS_TO_BE_DESIGNATED", "Allow approvers to be designated"),
    ALLOW_STANDARD_USERS_TO_USE_QUICK_SEARCH: new Message("ALLOW_STANDARD_USERS_TO_USE_QUICK_SEARCH", "Allow Standard users to use quick searches"),
    QUICK_SEARCHES: new Message("QUICK_SEARCHES", "Quick Searches"),
    ENABLE_FUZZY_ON_QUICK_SEARCHES: new Message("ENABLE_FUZZY_ON_QUICK_SEARCHES", "Always enable fuzzy search for quick searches"),
    SEARCH_DEFAULTS: new Message("SEARCH_DEFAULTS", "Search Defaults"),
    INCLUDE_DATA_FROM_PRIOR_SEARCHES: new Message("INCLUDE_DATA_FROM_PRIOR_SEARCHES", "Include data from prior searches"),
    AUTO_ASSIGN_SEARCH_REQUESTS_TO: new Message("AUTO_ASSIGN_SEARCH_REQUESTS_TO", "Auto-assign search requests to"),
    NONE: new Message("NONE", "None"),
    REQUESTED_BY: new Message("REQUESTED_BY", "Requested By"),
    CREATED_BY: new Message("CREATED_BY", "Created By"),
    APPROVER: new Message("APPROVER", "Approver"),
    FIRM_SETTINGS_LOAD_FAILED: new Message(
        "FIRM_SETTINGS_LOAD_FAILED",
        "The firm settings failed to load, so the defaults are being used instead. Please refresh the page.\r\nIf the problem persists, contact the system administrator."
    ),
    FIRM_SETTINGS_PAGE_LOAD_FAILED: new Message<[string]>(
        "FIRM_SETTINGS_PAGE_LOAD_FAILED",
        "The {0} settings failed to load. If you want to manage the {0} settings, please refresh the page.\r\nIf the problem persists, contact the system administrator."
    ),
    FIRM_SETTINGS_SAVE_FAILED: new Message("FIRM_SETTINGS_SAVE_FAILED", "The settings failed to save. Please try again."),
    //ToDo - US59978: Confirm the message wording
    SANCTIONS_LIST_SAVE_FAILED: new Message<[string]>("SANCTIONS_LIST_SAVE_FAILED", "The {0} data failed to save. Please try again."),
    SANCTIONS_LIST_SAVE_SUCCEEDED: new Message<[string]>("SANCTIONS_LIST_SAVE_SUCCEEDED", "The {0} data was imported successfully and will now be processed."),
    SANCTIONS_IMPORT_FILE_TOO_LARGE: new Message<[string]>("SANCTIONS_IMPORT_FILE_TOO_LARGE", "The {0} data file is too large."),
    SANCTIONS_IMPORT_FAILURE: new Message<[string]>("SANCTIONS_IMPORT_FAILURE", "The {0} data import failed. Try again, and if the problem persists, contact your system administrator."),
    DELETED_ENTITY_TYPE_SUCCEEDED: new Message<[string]>("DELETED_ENTITY_TYPE_SUCCEEDED", "The {0} data was deleted successfully.")
};
